import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiCast />,
    title: "FASHION PHOTOGRAPHY",
    description:
      "We provide leading, multi-channel digital content with a wealth of fashion and commercial industry experience that produces results.",
  },
  {
    icon: <FiLayers />,
    title: "E-COMMERCE PHOTOGRAPHY",
    description:
      "Working with top fashion, beauty, and marketing brands has enhanced our expertise in fashion e-commerce photography.",
  },
  {
    icon: <FiUsers />,
    title: "E-COMMERCE VIDEOGRAPHY",
    description:
      "Moving image is not a afterthought. Our expertise in still imagery makes it possible to produce incredibly captivating videos.",
  },
  {
    icon: <FiMonitor />,
    title: "PORTRAIT PHOTOGRAPHY",
    description:
      "Whether you prefer a contemporary professional headshot or fun and distinctive portraits that best express your individuality.",
  },
  {
    icon: <FiUsers />,
    title: "PRODUCT PHOTOGRAPHY",
    description:
      "We create excellent photographs that accurately represent the calibre of our clients' brands. to propel their company forward and sway prospective clients' split-second decisions.",
  },
  {
    icon: <FiMonitor />,
    title: "INTERVIEW FILMING",
    description:
      "Do you want your interview videos to stand out from the competition? For years, we've been developing distinctive interview styles. As a result, we have a variety of tactics and suggestions at our disposal.",
  },
];


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            {/* <a href="/service-details"> */}
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            {/* </a> */}
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
