import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return (
          <React.Fragment>
            <Helmet>
              {/* <title>{this.props.pageTitle} || Rainforest Template </title> */}
              <title>Rainforest Studio </title>
              <meta name="description" content="Rainforest Studio" />
            </Helmet>
          </React.Fragment>
        );
    }
}


export default PageHelmet;
