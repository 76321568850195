import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";

import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";




const PortfolioList = [
  {
    images: "7",
    category: "Freelancer",
    title: "The Language of Developer",
  },
  {
    images: "1",
    category: "Freelancer",
    title: "The new Thinking for Design",
  },
  {
    images: "2",
    category: "Freelancer",
    title: "The new Thinking for Design",
  },
  {
    images: "3",
    category: "Freelancer",
    title: "Getting tickets to the big shossw",
  },
  {
    images: "8",
    category: "Freelancer",
    title: "You can see your Portfolio",
  },
  {
    images: "9",
    category: "Freelancer",
    title: "Getting tickets to the big ssshow",
  },
];



const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/'},
]

class harryBrown extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Portfolio Details" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area  -- HEADER HERO */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--2"
          
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h1 className="title theme-gradient">Harry Brown</h1>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Portfolio Details */}

        <div className="rn-portfolio-details  bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="portfolio-details">
                  <div className="inner">
             
                    <p className="subtitle"> </p>
                    <p>
                      Harry Brown makes Traditional suits with a modern twist. They're
                      here for all your sartorial needs, and with a tailoring
                      collection that has something for every man. 
                    </p>

                    {/* PROJECT TYPE INFORMATION */}
                    {/* <div className="portfolio-view-list d-flex flex-wrap">
                      <div className="port-view">
                        <span>Branch</span>
                        <h4>Ability</h4>
                      </div>

                      <div className="port-view">
                        <span>Project Types</span>
                        <h4>Website</h4>
                      </div>

                      <div className="port-view">
                        <span>Program</span>
                        <h4>View Project</h4>
                      </div>
                    </div> */}

                    <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                      <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
                        {SocialShare.map((val, i) => (
                          <li key={i}>
                            <a href={`${val.link}`}>{val.Social}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="portfolio-thumb-inner">
                    {/*Video Section*/}
                    {/* <div className="thumb position-relative mb--30">
                       <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
                           <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            
                          <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                    </div> */}
                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/harryBrown/portfolio-big-01.gif"
                        alt="Portfolio Images"
                      />
                    </div>
                    {/* <Gallery /> */}

                    {/* Start Portfolio Area  */}
                    <div className="creative-portfolio-wrapper ptb--120 bg_color--1">
                      <div className="container plr--10">
                        <div className="row row--5">
                          {PortfolioList.map((value, i) => (
                            <div className="col-lg-4 col-md-6 col-12" key={i}>
                              <div className="portfolio-style--3">
                                <div className="">
                                  <img
                                    className="w-100"
                                    src={`/assets/images/portfolio/harryBrown/portfolio-${value.images}.jpg`}
                                    alt="Portfolio Images"
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* End Portfolio Area  */}

                    {/* <div className="thumb mb--60">
                      <img
                        src="/assets/images/portfolio/portfolio-big-02.jpg"
                        alt="Portfolio Images"
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Details */}

        {/* Start Related Work */}
        <div className="portfolio-related-work pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="theme-color font--18 fontWeight600">
                    Related Work
                  </span>
                  <h2>Our More Projects</h2>
                </div>
              </div>
            </div>
            <div className="row mt--10">
              {/* Start Single Portfolio */}
              <div className="col-lg-6 col-md-6 col-12">
                <div className="related-work text-center mt--30">
                  <div className="thumb">
                    <a href="/diida">
                      <img
                        src="/assets/images/portfolio/related-image-03.jpg"
                        alt="Portfolio-images"
                      />
                    </a>
                  </div>
                  <div className="inner">
                    <h4>
                      <a href="/diida">Diida</a>
                    </h4>
                    <span className="category">Fashion Photography</span>
                  </div>
                </div>
              </div>
              {/* End Single Portfolio */}
              {/* Start Single Portfolio */}
              <div className="col-lg-6 col-md-6 col-12">
                <div className="related-work text-center mt--30">
                  <div className="thumb">
                    <a href="/mashu">
                      <img
                        src="/assets/images/portfolio/related-image-02.jpg"
                        alt="Portfolio-images"
                      />
                    </a>
                  </div>
                  <div className="inner">
                    <h4>
                      <a href="/mashu">Mashu</a>
                    </h4>
                    <span className="category">Product Photography</span>
                  </div>
                </div>
              </div>
              {/* End Single Portfolio */}
            </div>
          </div>
        </div>
        {/* End Related Work */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default harryBrown;
