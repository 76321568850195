import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
// import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class About extends Component{
    render(){
        let title = "About",
          description =
            "RainForest Studio not only wants to create beautiful content for our clients but also help the Rainforest thrive that is why we aim to donate 10% of all profits to the Rainforest Trust. A charity that buys acres of land to protect Tropical Rainforests before they are forever lost.";
        return (
          <React.Fragment>
            <PageHelmet pageTitle="About" />

            <Header
              headertransparent="header--transparent"
              colorblack="color--black"
              logoname="logo.png"
              logo= "dark"
            />
            {/* Start Breadcrump Area */}
            <Breadcrumb title={"About"} />
            {/* End Breadcrump Area */}

            {/* Start About Area  */}
            <div className="rn-about-area ptb--120 bg_color--1">
              <div className="rn-about-wrapper">
                <div className="container">
                  <div className="row row--35 align-items-center">
                    <div className="col-lg-5">
                      <div className="">
                        <img
                          className="w-100"
                          src="/assets/images/about/about-3.png"
                          alt="About Images"
                        />
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="about-inner inner">
                        <div className="section-title">
                          <h2 className="title">{title}</h2>
                          <p className="description">{description}</p>
                        </div>
                        <div className="row mt--30">
                          <div className=" col-md-12 col-sm-12 col-12">
                            <div className="about-us-list">
                              <h3 className="title">Who we are</h3>
                              <p className="description">
                                Our Resident Photographer has over 5 years of
                                experience shooting for major brands in the
                                commercial and fashion space. Always delivering
                                spectacular imagery for a wide variety of
                                brands. We also have a team of make-up artists,
                                hair stylists, groomers, video editors, and
                                photo editors utilizing the latest techniques to
                                deliver fantastic content.
                              </p>
                            </div>
                          </div>
                          {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Who we are</h3>
                                                    <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                                </div>
                                            </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End About Area  */}

            {/* Start CounterUp Area */}
            <div className="rn-counterup-area pb--120 bg_color--1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title text-center">
                      <h3 className="fontWeight500">Our Fun Facts</h3>
                    </div>
                  </div>
                </div>
                <CounterOne />
              </div>
            </div>
            {/* End CounterUp Area */}

            {/* Start Finding Us Area  */}
            <div className="rn-finding-us-area rn-finding-us bg_color--1 pb--120">
              <div className="inner">
                <div className="content-wrapper">
                  <div className="content">
                    <h4 className="theme-gradient">Find out How</h4>
                    <p>
                      We can help elevate your brand and inspire your customers.
                      Every shoot is different. In order to maximise the return
                      on your investment, we will develop a custom pricing plan
                      that fits your requirements.
                    </p>
                    <a className="rn-btn btn-white" href="/contact">
                      Get in touch
                    </a>
                  </div>
                </div>
                <div className="thumbnail">
                  <div className="image">
                    <img
                      src="/assets/images/about/finding-us-01.png"
                      alt="Finding Images"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* End Finding Us Area  */}

            {/* Start Team Area  */}
            {/* <div className="rn-team-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25">
                                    <h2 className="title">Skilled Team</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                             */}
            {/* Start Single Team  */}
            {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/team-01.jpg" alt="Blog Images"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Jone Due</h4>
                                        <p className="designation">Sr. Web Developer</p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                    </ul>
                                </div>
                            </div> */}
            {/* End Single Team  */}

            {/* Start Single Team  */}
            {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/team-02.jpg" alt="Blog Images"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">BM. Alamin</h4>
                                        <p className="designation">Sr. Web Developer</p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                    </ul>
                                </div>
                            </div> */}
            {/* End Single Team  */}

            {/* Start Single Team  */}
            {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/team-03.jpg" alt="Blog Images"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Jone Due</h4>
                                        <p className="designation">Sr. Web Developer</p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                    </ul>
                                </div>
                            </div> */}
            {/* End Single Team  */}
            {/* 
                        </div>
                    </div>
                </div> */}
            {/* End Team Area  */}

            {/* Start Testimonial Area */}
            <div className="rn-testimonial-area bg_color--5 ptb--120">
              <div className="container">
                <Testimonial />
              </div>
            </div>
            {/* End Testimonial Area */}

            {/* Start Brand Area */}
            <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <BrandTwo />
                  </div>
                </div>
              </div>
            </div>
            {/* End Brand Area */}

            {/* Start Back To Top */}
            <div className="backto-top">
              <ScrollToTop showUnder={160}>
                <FiChevronUp />
              </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />
          </React.Fragment>
        );
    }
}
export default About